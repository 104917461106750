import { isArray } from "lodash"

export const searchSelectFilterId = (items = []) => items.map(function (item) {
    return item.value;
});

export const mapperCoveInValue = (arr = []) => {
    if (!isArray(arr))
        return [];

    let newArr = [];

    arr.map(item => {
        newArr.push({ title: item.title, value: item.code })
    });

    return newArr;
}

export const mapperCoveInlabel = (arr = []) => {
    if (!isArray(arr))
        return [];

    let newArr = [];

    arr.map(item => {
        newArr.push({ label: item.title, value: item.code })
    });

    return newArr;
}

export const mappedSelect = (arr = []) => {
    return arr?.map((item) => {
        return { id: item.value, label: item.title };
    }) ?? [];
};

export const mappedSelectedItem = (arr = [], selectedElement = null) => {
    const selectedItem = arr.find((item) => item.value == selectedElement);
    return selectedItem ? { value: selectedItem.value, label: selectedItem.title } : {};
};