import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';

// selectors
import { siteSettingsRestaurantSelector } from 'selectors/settings';

import { default as BannersPage } from './Banners';

function mapStateToProps(state) {
  const { id: selectedRestaurantId } = siteSettingsRestaurantSelector(state);
  const { settings, view } = state;
  const {
    banners: list,
    bannersEdit: {
      isOpen,
      data: formEditBanners,
    }
  } = settings;

  return {
    list,
    isOpen,
    formEditBanners,
    view,
    selectedRestaurantId,
  };
}

const mapDispatchToProps = {
  ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(BannersPage);
