import * as ROUTES from "./routes";

export const selectPages = [
    {
        label: "Call-центр",
        value: ROUTES.CALLCENTER,
    },
    {
        label: "Статистика",
        value: ROUTES.STATISTICS,
    },
    {
        label: "Співробітники",
        value: ROUTES.USERS,
    },
    {
        label: "Товари",
        value: ROUTES.PRODUCTS,
    },
    {
        label: "Клієнти",
        value: ROUTES.CLIENTS,
    },
    {
        label: "Налаштування",
        value: ROUTES.SETTINGS,
    },
    {
        label: "Кухня",
        value: ROUTES.KITCHEN,
    },
    {
        label: "Курьер",
        value: ROUTES.COURIER,
    },
    {
        label: "Кухарь",
        value: ROUTES.COOK,
    },
    {
        label: "Звіти",
        value: ROUTES.REPORTS,
    },
    {
        label: "Звіт по чеку",
        value: ROUTES.STATISTICS_SALES,
    },
];

export const pageStatistic = '/statistic';
export const selectPagesStatistic = [
    {
        label: "Кур'єри",
        value: '/couriers',
    },
    {
        label: "Замовлення",
        value: '/orders',
    },
    {
        label: "Cамовивіз",
        value: '/self_pickup',
    },
    {
        label: "Відгуки",
        value: '/reviews',
    },
    {
        label: "Скарги",
        value: '/complaints',
    },
];

export const selectPagesSettings = [
    {
        label: "Home",
        value: "/",
    },
    {
        label: "CRM",
        value: "/general",
    },
    {
        label: "Сайти",
        value: "/sites",
    },
];
