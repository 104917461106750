import { useEffect, useMemo, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { isArray } from "lodash";

import Back from "components/ui-kit/Back";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { LoadingModal } from "components/loadingOrder/loading";
import Select from "components/ui-kit/Select";

import SaveIcon from "@mui/icons-material/Save";
import styles from "./../../scss/settings.module.scss";

import { validateRole } from "utils/validation";
import { selectPages, selectPagesStatistic, selectPagesSettings } from "constants/selectPages";
import { SETTINGS, STATISTICS } from "constants/routes";

const Role = ({
    // state
    data,
    isLoadPage,
    // dispatch
    updateFormRole,
    updateFormRolePages,
    cleaningNewForm,
    getRoleId,
    updataRole,
    createRole,
}) => {
    let { id } = useParams();
    let history = useHistory();

    const [isPageStatistic, isPageSettings] = useMemo(() => {
        const pages = [STATISTICS, SETTINGS];
        if (isArray(data.pages)) {
            return pages.map(function (page) {
                const index = data.pages.findIndex((selected) => selected.value === page);
                return index !== -1;
            });
        }
        return Array(pages.length).fill(false);
    }, [data.pages]);

    const [error, setErrors] = useState({
        title: "",
        pages: "",
        statisticPages: "",
        settingsPages: "",
    });

    let titleButton = data?.id ? "Змінити" : "Створити";

    const createRoleCallback = () => {
        history.goBack();
    }

    const handleSubmit = () => {
        validateRole({
            data: {
                title: data.title,
                pages: data.pages,
            },

            onSuccess: async (validData) => {
                if (data?.id) {
                    updataRole();
                } else {
                    createRole(createRoleCallback);
                }

                setErrors({
                    name: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    const findSelectedSubpages = useCallback((path) => {
        const page = data.pages?.find((page) => page.value === path);
        return page?.pages || [];
    }, [data.pages]);

    const handleSelectAllStatisticsPages = useCallback(() => {
        updateFormRolePages(STATISTICS, selectPagesStatistic);
    }, [updateFormRolePages]);

    const handleSelectAllSettingsPages = useCallback(() => {
        updateFormRolePages(SETTINGS, selectPagesSettings);
    }, [updateFormRolePages]);

    useEffect(() => {
        if (id === "new") {
            cleaningNewForm("editRole");
        } else {
            getRoleId(id);
        }
    }, [getRoleId, cleaningNewForm]);

    return (
        <div className={`${styles.d_max_768}`}>
            <Back />

            <div className={`${styles.block}`}>
                <TextInput
                    title="Назва"
                    type="text"
                    value={data.title}
                    isStyle={true}
                    error={error.title}
                    onChange={(e) => updateFormRole("title", e.target.value)}
                />
            </div>

            <div className={`${styles.title_row} ${styles.row_mrt}`}>Загальні сторінки</div>

            <div className={`${styles.block}`}>
                <Select
                    selectOptions={selectPages}
                    selectedOption={data?.pages ? data.pages : []}
                    valueKey="pages"
                    label="Сторінки"
                    error={error.pages}
                    isMulti
                    mode="primary"
                    onChange={({ value }) => updateFormRole("pages", value)}
                />
            </div>

            {
                isPageStatistic && (
                    <div className={`${styles.block}`}>
                        <div className={`${styles.title_row} ${styles.row_mrt}`}>Сторінки по статистиці</div>
                        <br />
                        <div className={styles.row_sel}>
                            <Select
                                selectOptions={selectPagesStatistic}
                                selectedOption={findSelectedSubpages(STATISTICS)}
                                valueKey="statisticPages"
                                error={error.statisticPages}
                                isMulti
                                mode="primary"
                                onChange={({ value }) => updateFormRolePages(STATISTICS, value)}
                            />
                            <div className={styles.btn_sel}>
                                <ButtonIcon
                                    onClick={handleSelectAllStatisticsPages}
                                    title="Обрати всі"
                                    width="110px"
                                />
                            </div>
                        </div>
                    </div>
                )
            }

            {
                isPageSettings && (
                    <div className={`${styles.block}`}>
                        <div className={`${styles.title_row} ${styles.row_mrt}`}>Сторінки по налаштуванням</div>
                        <br />
                        <div className={styles.row_sel}>
                            <Select
                                selectOptions={selectPagesSettings}
                                selectedOption={findSelectedSubpages(SETTINGS)}
                                valueKey="settingsPages"
                                error={error.settingsPages}
                                isMulti
                                mode="primary"
                                onChange={({ value }) => updateFormRolePages(SETTINGS, value)}
                            />
                            <div className={styles.btn_sel}>
                                <ButtonIcon
                                    onClick={handleSelectAllSettingsPages}
                                    title="Обрати всі"
                                    width="110px"
                                />
                            </div>
                        </div>
                    </div>
                )
            }

            <div className={styles.row_btn}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
            </div>

            {
                isLoadPage && (
                    <LoadingModal isFixed={true} />
                )
            }
        </div>
    )
}

Role.propTypes = {
    data: PropTypes.object,
    isLoadPage: PropTypes.bool,
    updateFormRole: PropTypes.func,
    cleaningNewForm: PropTypes.func,
    getRoleId: PropTypes.func,
    updataRole: PropTypes.func,
    updateFormRolePages: PropTypes.func,
};

export default Role;
