import { useMemo, memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const SubpageSwitch = ({ path, pages, components }) => {

  const entries = useMemo(() => Object.entries(pages), [pages]);
  const fallback = useMemo(() => {
    check: if (entries.length) {
      const [[_key, route]] = entries;
      if (!route?.path && route?.path !== '') {
        break check;
      }
      return path + route.path;
    }
    return null;
  }, [path, entries]);

  return (
    <Switch>
      {entries.map(([key, page]) => {
        const component = components?.[key] ?? null;
        return component && (
          <Route
            key={key}
            component={component}
            exact={key === 'common'}
            path={`${path}${page.path}`}
          />
        );
      })}
      {/* If fallback is present redirect to user's first route */}
      {fallback && <Redirect to={fallback} />}
    </Switch>
  )
};

SubpageSwitch.propTypes = {
  path: PropTypes.string.isRequired,
  pages: PropTypes.object.isRequired,
  components: PropTypes.object.isRequired,
}

export default memo(SubpageSwitch);
