import * as React from "react";
import api from "apiSingleton";

import { find } from "lodash";
import { Stack } from "@mui/material";

import classnames from "classnames";
import classes from "./ModalClientInfo.module.scss";


const ItemGroup = ({ title, children, bigTitle = false }) => (
  <div className={classes.group}>
    <legend className={classnames({ [classes.header]: bigTitle })}>
      {title}
    </legend>
    <div className={classes.column}>
      {children}
    </div>
  </div>
);

const OrderItem = ({ article, title, quantity, sum, className }) => (
  <Stack direction="row" justifyContent="space-between" className={className}>
    <Stack direction="row" spacing={1.5}>
      <span>{article}</span>
      <span><b>{title}</b></span>
    </Stack>
    <Stack direction="row" spacing={1.5}>
      <span>{quantity}</span>
      <span>{sum} грн</span>
    </Stack>
  </Stack>
);

export const ModalContent = ({
  restaurants,
  data,
  data: {
    statistics = {},
    bonuses = {},
    address = {},
    latest_orders: latestOrders = [],
    favorite_items: favoriteItems = [],
  }
}) => {

  const [deliveryTime, setDeliveryTime] = React.useState("00 хв.");
  const restaurantName = React.useMemo(() => {
    return find(restaurants, { code: data.restaurant })?.name;
  }, [restaurants, data.restaurant]);

  React.useEffect(() => {
    if (data.phone && data.restaurant) {
      const controller = new AbortController();
      const signal = controller.signal;

      api.orders.getOrderDeliveryTime({
        data: {
          items: [],
          pickup: 0,
          restaurant: data.restaurant,
          kitchen_code: data.kitchen_code,
          client: { phone: data.phone },
          address: data.address,
        }
      }, signal).then(
        (response) => {
          if (response.errors) return;
          setDeliveryTime(`${response.deliveryTime} хв.`);
        },
        () => { },
      );

      return () => { controller.abort(); }
    }
  }, [data]);

  return (
    <div className="cont-modal-info">

      <ItemGroup title={
        <div className={classes.spaceBetween}>
          <span>{data.name} ID: {data.id}</span>
          <span>{restaurantName}</span>
          <span>Час очікування: {deliveryTime}</span>
        </div>
      } bigTitle>
        <div className={classes.container}>

          <ItemGroup title="Інформація про клієнта">
            <Stack spacing={1.5}>
              <span><b>Дата народження:</b> {data.date_of_birth}</span>
              <span><b>Телефон:</b> {data.phone}</span>
              <span>
                <b>
                  м. {address.city_name} {address.street} {address.house_number}
                </b>
              </span>
            </Stack>
          </ItemGroup>

          <ItemGroup title="Статистика клієнта">
            <div className={classes.row}>
              <div className={classnames(classes.column, classes.padless)}>
                <b className={classes.statisticsHeader} style={{ visibility: "hidden" }}>Замовлення</b>
                <span><b>Усього замовлень:</b> {statistics.total_orders}</span>
                <span><b>Закрито:</b> {statistics.closed}</span>
                <span><b>Скасовано:</b> {statistics.cancelled}</span>
              </div>
              <div className={classnames(classes.column, classes.padless)}>
                <b className={classes.statisticsHeader}>Бонуси</b>
                {Object.entries(bonuses).map(([restaurant, amount]) => (
                  <span key={restaurant}>
                    <b>{find(restaurants, { code: restaurant })?.name}:</b> {amount}
                  </span>
                ))}
              </div>
            </div>
          </ItemGroup>

          <ItemGroup title="Улюблена страва">
            <Stack spacing={1.5}>
              {favoriteItems.map((item) => (
                <OrderItem
                  key={item.article}
                  article={item.article}
                  title={item.title}
                  quantity={item.total_quantity}
                  sum={item.total_sum}
                />
              ))}
            </Stack>
          </ItemGroup>

          <ItemGroup title="Останні замовлення">
            {latestOrders.map((order) => (
              <div key={order.id}>
                <span><b>{order.date} № {order.id}</b></span>
                <div className={classes.column}>
                  {order.items.map((item, index) => (
                    <OrderItem 
                      key={index}
                      article={item.product.article}
                      title={item.product.title_ua}
                      quantity={item.quantity}
                      sum={item.sum} 
                      className={classes.paddedLeft} 
                    />
                  ))}
                </div>
              </div>
            ))}
          </ItemGroup>

        </div>
      </ItemGroup>

    </div>
  );
};
