import { first } from 'lodash';
import { createSelector } from 'reselect';
import { userRestaurantsSelector } from './user';

/* Site settings picked restaurant selector */
const getSelectedRestaurantId = (state) => state.settings.sites.selectedRestaurant;

export const siteSettingsRestaurantSelector = createSelector(
  [userRestaurantsSelector, getSelectedRestaurantId],
  (restaurants, selection) => {
    if (!restaurants.length) return;
    return restaurants.find(function (restaurant) {
      return restaurant.id === selection;
    }) ?? first(restaurants);
  },
);
