import { connect } from 'react-redux';

import * as settingsActions from '../../store/actions/settings';

// selector
import { userRestaurantsSelector, userSubpageSelector } from 'selectors/user';

// constants
import { SETTINGS } from 'constants/routes';
import { dataItemsMenu } from 'constants/menuPageSettings';

import { default as SettingsPage } from './Settings';

const mapStateToProps = (state) => ({
    pages: userSubpageSelector(state, SETTINGS, dataItemsMenu),
    restaurants: userRestaurantsSelector(state),
});

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
