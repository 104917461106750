import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";

import MenuGeneral from "components/menuGeneral";

import styles from "./../scss/settings.module.scss";

import { generalDataItemsMenuSites } from 'constants/menuPageSettings';

// local
import PageCommon from "./Common";

// global
import PagePayment from "pages/Settings/Payment";
import PageBanners from "pages/Settings/Banners";
import { CategoriesPageSites as PageCategories } from "pages/Settings/Categories";
import PageClients from "pages/Clients";
import PageProvingGrounds from "pages/Settings/ProvingGrounds";

const NotAvailable = () => (
    <b>Ви ще не приєднані до жодного бренду</b>
);

const Home = ({ available }) => {
    let { path } = useRouteMatch();

    return (
        <div className={styles.ge_wr}>
            <div className={styles.ge_menu}>
                <MenuGeneral items={generalDataItemsMenuSites} />
            </div>
            <div className={styles.ge_main}>
                <Switch>
                    {available ? (
                        <>
                            <Route component={PageCommon} exact path={`${path}${generalDataItemsMenuSites['common'].path}`} />
                            <Route component={PageBanners} exact path={`${path}${generalDataItemsMenuSites['banners'].path}`} />
                            <Route component={PageProvingGrounds} exact path={`${path}${generalDataItemsMenuSites['proving_ground'].path}`} />
                            <Route component={PagePayment} exact path={`${path}${generalDataItemsMenuSites['payment'].path}`} />
                            <Route component={PageClients} exact path={`${path}${generalDataItemsMenuSites['clients'].path}`} />
                            <Route component={PageCategories} exact path={`${path}${generalDataItemsMenuSites['categories'].path}`} />
                        </>
                    ) : <Route component={NotAvailable} />}
                </Switch>
            </div>
        </div>
    )
}

export default Home;