export const pushQuery = (history, params) => {
  /*
  History: react-router history object
  Params: {a: 1, b: 2}
  */

  const entries = Object.entries(params);
  const filtered = entries.filter(function ([key, value]) {
    return key && (value !== null && value !== undefined);
  });

  if (!filtered.length) return;

  const search = new URLSearchParams(Object.fromEntries(filtered));
  return history.push('?' + search.toString());
}
