import { connect } from "react-redux";
import { default as SitesRestaurantPicker } from "./SitesRestaurantPicker";

// selectors
import { userRestaurantsSelector } from "selectors/user";
import { siteSettingsRestaurantSelector } from "selectors/settings";

// actions
import { selectSiteSettingsRestaurant } from "store/actions/settings";

const mapStateToProps = (state) => ({
  view: state.view,
  restaurants: userRestaurantsSelector(state),
  selectedRestaurant: siteSettingsRestaurantSelector(state),
});

const mapDispatchToProps = { selectSiteSettingsRestaurant };

export default connect(mapStateToProps, mapDispatchToProps)(SitesRestaurantPicker);
