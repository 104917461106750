import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { updateModalDelete } from 'store/actions/view';

// selectors
import { siteSettingsRestaurantSelector } from 'selectors/settings';

import { default as CategoriesPage } from './Categories';

function mapStateToProps(state) {
    const {
        view,
        settings: {
            productCategories,
            productCategoriesForm,
            cutlery,
        }
    } = state;

    return {
        productCategories,
        productCategoriesForm,
        cutlery,
        isOpenModalDelete: view.modalDelete.isOpen,
        isLoadModalDelete: view.modalDelete.loadingsModal,
    };
}

const mapDispatchToProps = {
    updateModalDelete,
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesPage);

// Categories page for sites
function mapStateToPropsSites(state) {
    const { code: restaurant } = siteSettingsRestaurantSelector(state);
    return {
        ...mapStateToProps(state),
        categoriesForBrands: true,
        categoriesParams: {
            parent_id: 0,
            restaurant,
        },
    }
}

export const CategoriesPageSites = connect(mapStateToPropsSites, mapDispatchToProps)(CategoriesPage);
