import { connect } from "react-redux";

import * as usersActions from "../../../store/actions/users";
import * as viewActions from "../../../store/actions/view";
import * as userActions from "../../../store/actions/user";
import * as settingsActions from "../../../store/actions/settings";
import { default as Users } from "./Users";

function mapStateToProps(state) {
  const productTypes = state.settings.productCategories.map((el) => ({
    value: el.sync_id,
    label: el.name,
  }));

  return {
    user: state.user.user,
    isOpen: state.view?.modals.length > 0,
    modals: state.view.modals,
    editedUser: state.users.editedUser,
    cities: [...[{ name_ua: '-', sync_id: null, status: 1 }], ...state.settings.cities],
    restaurants: state.settings.restaurants,
    kitchens: state.settings.kitchens,
    locationsState: state.settings.locations,
    isLoadModalUser: state.view.isLoadModalUser,
    roles: state.settings.roles,
    productTypes,
    isOpenModalDelete: state.view.modalDelete.isOpen,
    isLoadModalDelete: state.view.modalDelete.loadingsModal,
    transport: state.settings.transport
  };
}

const mapDispatchToProps = {
  ...viewActions,
  ...usersActions,
  ...userActions,
  ...settingsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
