import { connect } from "react-redux";
import PageCommon from "./Common";

// actions
import { 
  updateSiteSettingsFormBrands,
  getSiteSettingsFormBrands,
  submitSiteSettingsFormBrands,
} from "store/actions/settings";

// selectors
import { siteSettingsRestaurantSelector } from "selectors/settings";

const mapStateToProps = (state) => ({
  isLoadPage: state.view.isLoadPage,
  isLoadEdit: state.view.isLoadEditSiteSettingsBrands,
  data: state.settings.sites.brandSettingsForm,
  restaurant: siteSettingsRestaurantSelector(state),
});

const mapDispatchToProps = {
  updateSiteSettingsFormBrands,
  getSiteSettingsFormBrands,
  submitSiteSettingsFormBrands,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCommon);
