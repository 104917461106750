import api from "../../../../apiSingleton";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { isArray, find, debounce } from "lodash";

import {
  removeOrderItem,
  increaseOrderItem,
  decreaseOrderItem,
  updateOrderItemComment,
  updateDopAdd,
  removeDopAll,
  removeDopItem,
  searchDopCard,
  oneAddDop,
  oneSubtractDop,
  updateOrderData,
  updateCutlery,
  removeCutleryItem,
  editCuantityOrder,
  selectSwappableProduct,
  selectPizzaHalf,
} from "store/actions/order";

import { TabProducts } from "./TabProducts";
import { IconButton } from "components/ui-kit/buttons/IconButton";
import { TextArea } from "components/ui-kit/inputs/TextArea";
import Radio from "components/ui-kit/Radio";
import ModalDelete from "components/modals/ModalDelete";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Select from "components/ui-kit/Select";
import { TextInput } from "components/ui-kit/inputs/TextInput";

import { priceOrCity, getAdditionPrice } from "utils/helpers/price";
import { deliveresMapped } from "utils/mappers/delivery";
import { countDops, searchPaymentCodeAndReturn } from "utils/helpers/payment";
import { isSwappableProduct } from "utils/helpers/orders";

import { RADIO_SCHEME_IGNORE_STOCK, BLACK_LIST } from "constants/schemes";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { removeCutleryCard } from "utils/helpers/cutlery";

const RenderDops = ({
  idCard = 0,
  availableDopsList = [],
  dopsList = [],
  activeDopsList = [],
  indexProduct = 0,
  numberQuantity = 1,
}) => {
  const dispatch = useDispatch();
  const orderState = useSelector(state => state.order.data);
  const [city, deliveryType] = useSelector((state) => [
    state.order.data.address.city_sync_id,
    +state.order.data.delivery_type_id,
  ]);

  const [isOpenDop, setIsOpenDop] = useState(false);
  const [isRadion, setIsRadion] = useState(-1);

  const handlerAddDop = (type, dop) => {
    let price = null;

    price = priceOrCity(orderState.address.city_sync_id, dop.prices);

    if (price !== null) {
      if (type === 'radio') {
        if (isRadion === -1) {
          dispatch(updateDopAdd(indexProduct, dop.id, dop.name, price, type, numberQuantity));

          setIsRadion(dop.id);
        }
        return;
      }

      dispatch(updateDopAdd(indexProduct, dop.id, dop.name, price, type, numberQuantity));
    }
  }

  const handlerRemoveDopItem = (item) => {

    setIsRadion((prev) => {
      return prev === item.addition_id ? -1 : prev;
    });

    dispatch(removeDopItem(indexProduct, item.addition_id, item.price))
  }

  const handlerDeleteAllDop = () => {
    dispatch(removeDopAll(indexProduct));
    setIsRadion(-1);
  }

  const handlerOpenDops = () => {
    setIsOpenDop(prev => !prev);

    if (orderState.status !== "draft") {
      dispatch(searchDopCard(idCard));
    }
  }

  const addDop = (indexProduct, indexDop) => {
    dispatch(oneAddDop(indexProduct, indexDop, numberQuantity));
  }

  const subtractDop = (indexProduct, indexDop) => {
    dispatch(oneSubtractDop(indexProduct, indexDop, numberQuantity));
  }

  return (
    <QuantityDop>
      {
        isArray(dopsList) && (activeDopsList.length > 0) && (
          <WrDop>
            <TitleDop>Допи: </TitleDop>
          </WrDop>
        )
      }
      {
        isArray(activeDopsList) && (activeDopsList?.length > 0) && (
          <UlDops>
            <RowDopHead>
              <DopName>Назва</DopName>
              <DopCol>Кіл.</DopCol>
              {
                isArray(activeDopsList) && (activeDopsList.length > 0) && (
                  <DopDelete>
                    <IconButton
                      icon={<DeleteIcon />}
                      onClick={handlerDeleteAllDop}
                    />
                  </DopDelete>
                )
              }
            </RowDopHead>
            {
              isArray(activeDopsList) && (activeDopsList.length > 0) && activeDopsList.map((item, index) => {
                const addition = find(availableDopsList, { id: item.addition_id });
                if (!addition)
                  console.warn(`Addition not found in available additions list. Should not happen.`, item);

                const price = getAdditionPrice(addition ?? item, city, deliveryType);
                return (
                  <RowDop key={index}>
                    <DopName title={item?.addition?.name}>{`${item?.addition?.name} - Ціна: ${(price * item.quantity) * numberQuantity} грн.`}</DopName>
                    <DopCol>
                      <IconButton
                        icon={<RemoveIcon />}
                        onClick={() => subtractDop(indexProduct, index)}
                        disabled={1 >= item.quantity}
                      />
                      {item.quantity}
                      <IconButton
                        icon={<AddIcon />}
                        onClick={() => addDop(indexProduct, index)}
                        disabled={orderState?.id ? item?.addition_group?.[0]?.type === "radio" : item?.type === "radio"}
                      />
                    </DopCol>
                    <DopDelete>
                      <IconButton
                        icon={<DeleteIcon />}
                        onClick={() => handlerRemoveDopItem(item)}
                      />
                    </DopDelete>
                  </RowDop>
                )
              })
            }
          </UlDops>
        )
      }

      {
        (isArray(dopsList) && (dopsList.length > 0)) && (
          <WrDopBot>
            <TitleDopBtn>Додати доп: </TitleDopBtn>
            <IconButton
              icon={<AddIcon />}
              onClick={() => handlerOpenDops()}
            />
          </WrDopBot>
        )
      }

      {
        isOpenDop && (
          <>
            <OpasDops onClick={() => setIsOpenDop(prev => !prev)} />
            <Dops>
              <DopsHead>
                <span>Доступні допи: </span>
                <CloseButton onClick={() => setIsOpenDop(prev => !prev)}>
                  <CloseIcon style={{ fontSize: 28 }} />
                </CloseButton>
              </DopsHead>
              <Oldops>
                {
                  isArray(dopsList) && dopsList.map((itemDop, index) => {
                    return (
                      <div key={index}>
                        <ItemOld>
                          <span style={{ color: '#EDA240', fontSize: 14, paddingBottom: 10 }}>{itemDop.name}:</span>
                        </ItemOld>
                        {
                          isArray(itemDop.additions) && itemDop.additions.map((dop, index) => {
                            const price = getAdditionPrice(dop, city, deliveryType);
                            return (
                              <ItemOld key={index}>
                                <SpanTitleDop>{`${dop.name} - ціна: ${price} грн.`}</SpanTitleDop>
                                <IconButton
                                  icon={<AddIcon />}
                                  disabled={(itemDop.type === 'radio') && (isRadion != -1)}
                                  onClick={() => handlerAddDop(itemDop.type, dop)}
                                />
                              </ItemOld>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </Oldops>
            </Dops></>
        )
      }
    </QuantityDop>
  )
}

// list card
const RenderItemList = ({
  products,
  order,
  city,
  restaurant,
  deliveryType,
  onOpenDeleteModal,
}) => {
  const [indexOpenMain, setIndexOpenMain] = useState({
    index: undefined,
    id: undefined
  });

  return products.map((item, index) => {
    let price = +item.price;
    let pricePeint = +item.sum;

    return (
      <RenderItem
        key={`${item.id}-${index}`} // index
        index={index}
        id={item.id}
        item={item}
        order={order}
        price={price}
        pricePeint={pricePeint}
        city={city}
        restaurant={restaurant}
        deliveryType={deliveryType}
        onOpenDeleteModal={onOpenDeleteModal}
        indexOpenMain={indexOpenMain}
        setIndexOpenMain={setIndexOpenMain}
        products={products}
      />
    );
  });
};

// Item card
const RenderItem = ({
  index,
  id,
  item,
  order,
  price,
  city,
  restaurant,
  deliveryType,
  pricePeint,
  onOpenDeleteModal,
  indexOpenMain,
  setIndexOpenMain,
  products
}) => {
  const dispatch = useDispatch();
  const priceDops = useMemo(() => {
    return countDops({
      item,
      city,
      deliveryType,
    });
  }, [item, city, deliveryType]);

  const [firstPizzaHalf, secondPizzaHalf] = useMemo(() => {
    return item?.halves || Array(2).fill(undefined);
  }, [item?.halves]);

  const [isOpenComment, setIsOpenComment] = useState(false);
  const [isOpenMain, setIsOpenMain] = useState(false);

  const fetchPizzaHalves = useCallback(async (search) => {
    const params = {
      search_halves: 1,
      city_sync_id: city,
      delivery_type_id: deliveryType,
      restaurant: restaurant,
      search: search,
    };

    const { data } = await api.products.getList(params);
    const { title } = (firstPizzaHalf || secondPizzaHalf) || {};
    const allowedPizzaSize = title?.match(/\d+/g)?.slice(-1);

    const output = data.map(({ title_ua: label, ...value }) => ({
      label,
      value: {
        title: label,
        ...value,
      },
    }));

    return output.filter((item) => {
      return !allowedPizzaSize || item.label?.includes(allowedPizzaSize);
    });

  }, [city, restaurant, deliveryType, firstPizzaHalf, secondPizzaHalf]);

  const choosePizzaHalf = useCallback(({
    value,
    valueKey: { half, index },
  }) => {
    if (value !== null) {
      const { value: product } = value;
      return dispatch(selectPizzaHalf(index, half, product));
    }
    dispatch(selectPizzaHalf(index, half, undefined));
  }, [dispatch]);

  useEffect(() => {
    setIsOpenComment(!!item.comment);

    setIsOpenMain((prev) => {
      let status = false;

      if (index == 0) {
        status = true;
      } else {
        if (indexOpenMain.id == id && indexOpenMain.index == index) {
          status = true;
        }
      }

      return status;
    });
  }, [indexOpenMain, products]);

  const hadlOpenMain = useCallback(() => {
    setIndexOpenMain((prev) => {
      if (prev.id == id && prev.index == index)
        return { index: undefined, id: undefined };

      return { index, id };
    });
  }, [index, id])

  const handleQuantityOrder = (index, e, item) => {
    let v = +e.target.value.replace(/[^\d]/g, '');

    if (v <= 0)
      v = 1;

    if (v >= 50)
      v = 50;

    dispatch(editCuantityOrder(index, v, item))
  }

  const isPizzaHalves = (item?.product?.article ?? item?.article) === "00005";
  return (
    <Item isGift={item?.is_gift} isPizzaHalves={isPizzaHalves} isStrock={item?.stock || false}>
      {
        index != 0 && (
          <RowBtnClos onClick={hadlOpenMain}>
            {
              (indexOpenMain.id == id) && (indexOpenMain.index == index) ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />
            }
          </RowBtnClos>
        )
      }

      <Roew>
        <Title>
          {isSwappableProduct(item, order) ? (
            <Dropdown
              title="Позиція на вибір"
              width="300px"
              onChange={(event) => {
                const id = event.target.value;
                dispatch(selectSwappableProduct(id, item.status));
              }}
              value={item?.id}
              list={(order[`${item.status}s`] || []).map(({ id, product }) => ({
                title: product.title_ua,
                value: id,
              }))}
            />
          ) : item?.product?.title_ua ?? item?.title_ua ?? item?.title}
        </Title>
        {(isPizzaHalves && isOpenMain) && (
          <RowArt>
            <SmCol>
              <Select
                label='Перша половинка'
                placeholder='Оберіть половинку'
                onChange={choosePizzaHalf}
                selectedOption={firstPizzaHalf && { label: firstPizzaHalf.title, value: firstPizzaHalf.article }}
                valueKey={{ index, half: 'first_half' }}
                loadOptions={fetchPizzaHalves}
                isSearchable
                isClearable
                async
              />

              {(firstPizzaHalf || secondPizzaHalf) && (
                <PizzaHalfInfo isVisible={firstPizzaHalf}>
                  <Text><span>Ціна:</span> {firstPizzaHalf?.price ?? '-'} грн.</Text>
                  <Text><span>Арт:</span> {firstPizzaHalf?.article ?? '-'}</Text>
                </PizzaHalfInfo>
              )}

            </SmCol>
            <SmCol>
              <Select
                label='Друга половинка'
                placeholder='Оберіть половинку'
                onChange={choosePizzaHalf}
                selectedOption={secondPizzaHalf && { label: secondPizzaHalf.title, value: secondPizzaHalf.article }}
                valueKey={{ index, half: 'second_half' }}
                loadOptions={fetchPizzaHalves}
                isSearchable
                isClearable
                async
              />

              {(firstPizzaHalf || secondPizzaHalf) && (
                <PizzaHalfInfo isVisible={secondPizzaHalf}>
                  <Text><span>Ціна:</span> {secondPizzaHalf?.price ?? '-'} грн.</Text>
                  <Text><span>Арт:</span> {secondPizzaHalf?.article ?? '-'}</Text>
                </PizzaHalfInfo>
              )}

            </SmCol>
          </RowArt>
        )}
        {(!isOpenMain && isPizzaHalves) && (
          <RowArt>
            <div>
              {firstPizzaHalf && (<Text><span>Зліва: </span> {firstPizzaHalf.title}</Text>)}
              {secondPizzaHalf && (<Text><span>Справа: </span> {secondPizzaHalf.title}</Text>)}
            </div>
          </RowArt>
        )}
        <RowArt>
          <div style={{ display: 'flex', gap: '10px' }}>
            {(!isPizzaHalves || !isOpenMain) && (<Text><span>Ціна:</span> {price} грн.</Text>)}
            {/* ^ Якщо не половинки, то завжди відображаємо ціну продукту. Якщо половинки - тільки у згорнутому стані */}
            {
              !isOpenMain && (
                <Text><span>Кількість:</span> {item.quantity} шт.</Text>
              )
            }
          </div>
          {!isPizzaHalves && (
            <Text><span>Арт:</span> {item?.product?.article ?? item?.article}</Text>
          )}
        </RowArt>
        <ButtonHolder>
          <IconButton
            icon={<DeleteIcon />}
            onClick={() => onOpenDeleteModal(item)}
          />
        </ButtonHolder>

        {isOpenMain && (
          <MainWrap>
            <Quantity>
              <ButtonBar>
                <div>Кількість:</div>
                {
                  item?.is_gift != 1 && (
                    <IconButton
                      icon={<RemoveIcon />}
                      disabled={(isArray(item?.additions) && item.additions.length > 0)}
                      onClick={() => dispatch(decreaseOrderItem(index, item))}
                    />
                  )
                }
                {
                  item.is_gift != 1 && (
                    <div className="int int_quantity">
                      <TextInput
                        width="54px"
                        height="22px"
                        min={1}
                        max={80}
                        value={item.quantity}
                        onChange={(e) => handleQuantityOrder(index, e, item)}
                      />
                    </div>
                  )
                }
                <div className={` ${item.is_gift == 1 ? 'ml-1' : ''}`}> {item.is_gift == 1 ? 1 : ' '} шт.</div>
                {
                  item?.is_gift != 1 && (
                    <IconButton
                      icon={<AddIcon />}
                      disabled={(isArray(item?.additions) && item.additions.length > 0)}
                      onClick={() => dispatch(increaseOrderItem(index, item))}
                    />
                  )
                }
              </ButtonBar>
              <div>Всього: {pricePeint + priceDops} грн.</div>
            </Quantity>

            {
              (item.quantity == 1) && (
                <RenderDops
                  idCard={item.id}
                  availableDopsList={item?.product.additions}
                  activeDopsList={item?.additions}
                  dopsList={order.id ? order.status == "new" ? item?.product?.addition_groups : undefined : item?.product?.addition_groups}
                  indexProduct={index}
                  numberQuantity={item.quantity}
                />
              )
            }

            {
              !!item?.product?.addition_groups?.length && (item.quantity > 1) && (
                <ErMin>Якщо кількість більше ніж 1 то блокуються допи</ErMin>
              )
            }

            {
              isArray(item?.additions) && (item?.additions.length > 0) && (
                <ErMin>Якщо задіяні допи то блокується додавання кількості</ErMin>
              )
            }

            <RowCom>
              Коментар
              {
                !item.comment && (
                  <div>
                    <button onClick={() => setIsOpenComment(prev => !prev)}>{isOpenComment ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />} </button>
                  </div>
                )

              }
            </RowCom>

            {
              isOpenComment && (
                <TextArea
                  onChange={(e) =>
                    dispatch(updateOrderItemComment(index, e.target.value))
                  }
                  value={item.comment}
                />
              )
            }
          </MainWrap>
        )}

        {(!isOpenMain && !!item?.additions?.length) && (
          <ShortDops>
            {item.additions.map(({ quantity, addition_id: id, ...itemAddition }, i) => {
              const addition = find(item.product.additions, { id });
              if (!addition)
                console.warn(`Addition not found in available additions list. Should not happen.`, id, itemAddition);

              const price = getAdditionPrice(addition ?? itemAddition, city, deliveryType);
              return (
                <p key={`short-addition-display-${id}`}>
                  {i + 1}. {itemAddition.addition?.name} x{quantity} ({price * quantity} грн.)
                </p>
              );
            })}
          </ShortDops>
        )}

      </Roew>
    </Item>
  )
}

const CutleryItem = ({ data, index }) => {
  const dispatch = useDispatch();

  return (
    <Item>
      <Roew>
        <RowArtJus>
          <Title>{data.name}</Title>
          <RowArt>
            {
              data.price && (<Text><span>Ціна:</span> {data.price} грн.</Text>)
            }
            <Text><span>Арт: </span>{data.code}</Text>
          </RowArt>
          <ButtonHolder>
            <IconButton
              icon={<DeleteIcon />}
              onClick={() => dispatch(removeCutleryItem(index))}
            />
          </ButtonHolder>
        </RowArtJus>
        <RowBordTop>
          <Radio
            colors={"#ffffff"}
            items={BLACK_LIST}
            label="Потрібні столові прибори ? "
            valueKey="status"
            value={data.status || 1}
            onChange={(e) => {
              dispatch(updateCutlery(data.cutlery_set_id, "status", e.value.value));
            }}
          />
        </RowBordTop>
        {
          (data.status == 1) && (
            <RowArtJusNe>
              <Quantity>
                <ButtonBar>
                  <div>Кількість:</div>
                  <IconButton
                    icon={<RemoveIcon />}
                    onClick={() => dispatch(updateCutlery(data.cutlery_set_id, "quantity", data.quantity - 1))}
                    disabled={data.quantity <= 1}
                  />
                  <div className="int">
                    <TextInput
                      width="68px"
                      type="number"
                      min={1}
                      max={100}
                      value={data.quantity}
                      onChange={(e) => {
                        dispatch(updateCutlery(data.cutlery_set_id, "quantity", e.target.value));
                      }}
                    />
                  </div>
                  <IconButton
                    icon={<AddIcon />}
                    onClick={() => dispatch(updateCutlery(data.cutlery_set_id, "quantity", data.quantity + 1))}
                    disabled={data.quantity >= 100}
                  />
                </ButtonBar>
                {
                  data.price && (
                    <div>Всього: {data.quantity * data.price} грн.</div>
                  )
                }
              </Quantity>
            </RowArtJusNe>
          )
        }
      </Roew>
    </Item>
  )
}

const Cutlery = ({ data }) => {
  return (
    <WrCutlery>
      <TitleCutlery>Столові прибори</TitleCutlery>

      <ListCutlery>
        {
          data.map((item, index) => (
            <CutleryItem
              index={index}
              key={index}
              data={item}
            />
          ))
        }
      </ListCutlery>
    </WrCutlery>
  )
}

export const TabOrder = ({
  isEdit,
  calAllSum
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [isOpenModalDelete, setIsOpenModalDelete] = useState({
    isOpen: false,
    id: undefined,
  })

  const order = useSelector((state) => state.order.data);
  const products = useSelector((state) => state.order.data.items);
  const [restaurant, city, deliveryType] = useSelector((state) => [
    state.order.data.restaurant,
    state.order.data.address.city_sync_id,
    +state.order.data.delivery_type_id,
  ]);

  const typeListDeliver = useSelector((store) => store.settings.typesOfDelivery.list);

  const {
    settings: {
      typesOfDelivery
    }
  } = useSelector(state => state);

  useEffect(() => {
    removeCutleryCard(order, dispatch);
  }, [order.items]);

  const onOpenDeleteModal = (item) => {
    setIsOpenModalDelete({
      isOpen: true,
      item
    });
  }

  const clearStateDeleteModal = () => {
    setIsOpenModalDelete({
      isOpen: false,
      item: undefined,
    });
  }

  const onCloseModal = () => {
    clearStateDeleteModal();
  }

  const onDeleteItem = () => {
    dispatch(removeOrderItem(isOpenModalDelete.item));
    onCloseModal();
  }

  return (
    <Wrapper theme={theme} isEdit={isEdit}>
      <Wr>
        <Radio
          colors={"#ffffff"}
          items={RADIO_SCHEME_IGNORE_STOCK}
          label="Ігнорувати акції ?"
          valueKey="status"
          value={order?.ignore_stock}
          disabled={!isEdit}
          onChange={(e) => {
            dispatch(updateOrderData(
              "ignore_stock",
              e.value.value,
              true
            ));
          }}
        />
        <WrSel>
          <Dropdown
            title="Тип доставки"
            width="185px"
            onChange={(e) => {
              dispatch(updateOrderData("delivery_type_id", e.target.value, true));
              dispatch(updateOrderData("payments", [searchPaymentCodeAndReturn(e.target.value, typesOfDelivery.list)]));
            }}
            value={order.delivery_type_id}
            list={deliveresMapped(typesOfDelivery.list)}
            disabled={!isEdit}
          />

          <TextInput
            width="100px"
            title="Персон"
            type="number"
            disabled={!isEdit}
            onChange={(e) => {
              dispatch(updateOrderData("persons", e.target.value, true));
            }}
            value={order.persons}
          />
        </WrSel>
      </Wr>

      <WrPrices>Загальна сума: <span>{order?.items?.length > 0 ? calAllSum : 0}</span> грн.</WrPrices>
      <List>
        {products.length > 0 ? (
          <RenderItemList
            products={products}
            order={order}
            city={city}
            restaurant={restaurant}
            deliveryType={deliveryType}
            onOpenDeleteModal={onOpenDeleteModal}
            typeListDeliver={typeListDeliver}
          />
        ) : (
          <Subtext>Немає позицій</Subtext>
        )}
      </List>

      {
        isArray(order.cutlery_sets) && (order.cutlery_sets.length > 0) && (
          <Cutlery data={order.cutlery_sets} />
        )
      }

      {
        isEdit && (
          <TabProducts />
        )
      }

      <ModalDelete
        title={'Видалення позиції'}
        text={`Ви підтверджуєте видалення ?`}
        isOpen={isOpenModalDelete.isOpen}
        funDelete={onDeleteItem}
        onCloseModal={onCloseModal}
      />
    </Wrapper>
  );
};

const RowBordTop = styled.div`
  border-top: 1px solid #393C49;
  padding: 8px 0 0 0;
  margin: 4px 0 0 0 ;
`;

const RowArtJusNe = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  & > div {
    flex: 1 1 auto;
  }
`;

const RowArtJus = styled.div`
  width: 100%;
`;

const TitleCutlery = styled.div`
  font-size: 14px;
  color: #fff;
`;

const WrCutlery = styled.div`
  margin-top: 16px;
  border-top: 1px solid #EDA240;
  padding-top: 6px;
`;

const ListCutlery = styled.ul``;

const ErMin = styled.div`
  padding: 0 0 6px 0;
  color: red;
  font-size: 12px;
`;

const ShortDops = styled.div`
  color: white;
  font-size: 12px;
`;

const RowBtnClos = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0;
  margin-right: 10px;
  color: ${(p) => p.theme.accentColor};
  border: 1px solid ${(p) => p.theme.accentColor};
  font-size: 14px;
    &:hover {
      border-color: #fff;
    }
`;

const MainWrap = styled.div``;

const RowCom = styled.div`
  margin-left: 4px;
  color: #FFF;
  font-size: 14px;
  display: flex;
  align-items: center;
  & > div {
    margin-left: 10px;
  }
  button {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.accentColor};
    svg {
      font-size: 16px;
    }
  }
`;

const Wr = styled.div`
  padding-bottom: 5px;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  border-bottom: ${(p) => p.theme.inputBorder};
  display: flex;
  align-items: center;
`;

const WrPrices = styled.div`
  color: #fff;
  text-aling: center;
  border-bottom: ${(p) => p.theme.inputBorder};
  padding-bottom: 5px;
  margin-bottom: 5px;
  span {
    color: #EDA240;
  }
`;

const WrSel = styled.div`
  margin-left: 10px;
  display: flex;
  span {
    padding-bottom: 0;
  }
  &>div {
    margin: 0;
    padding-top: 0;
    &:nth-child(2) {
      margin-left: 8px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const List = styled.ul``;

const Item = styled.li`
  position: relative;
  margin: 5px 5px 8px 5px;
  padding: 10px;
  background-color:  ${(p) => p.isGift || p.isPizzaHalves ? "#244b4a" : p.isStrock ? "#b64e50" : p.theme.backgroundLight};
  border: ${(p) => p.theme.inputBorder};
  border-radius: 10px;
  display: flex;
`;
const Roew = styled.div`
  flex: 1 1 auto;
`;

const SmCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PizzaHalfInfo = styled.div`
  ${(p) => !p.isVisible ? 'visibility: hidden' : ''}
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;
  color: ${(p) => p.theme.accentColor};
`;

const Quantity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  margin: 4px 0;
  border-top: ${(p) => p.theme.inputBorder};
  border-bottom: ${(p) => p.theme.inputBorder};
  color: ${(p) => p.theme.secondaryColor};
`;

const QuantityDop = styled.div`
  color: ${(p) => p.theme.secondaryColor};
  position: relative;
`;

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  .int {
    div {
      margin: 0;
      padding: 0;
    }
    span {
      0
    }
  }
  input {
    margin: 0;
  }
`;

const ButtonHolder = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const RowArt = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  & > div {
    &:first-child {
      margin-right: 10px;
    }
  }
`;

const Text = styled.div`
  margin-bottom: 5px;
  color: ${(p) => p.theme.secondaryColor};
  & > span {
    color: ${(p) => p.theme.accentColor};
  }
`;

const Subtext = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.lightAccentColor};
  padding-top: 10px;
`;

const Warning = styled.div`
  position: absolute;
  right: 10px;
  bottom: 2px;
  color: #f90716;
`;

const WrDop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrDopBot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleDop = styled.div`
  font-size: 14px;
  color: ${(p) => p.theme.accentColor};
  padding-bottom: 5px;
`;

const UlDops = styled.div`
  margin: 0 -8px 0 -8px;
  padding: 2px 0;
`;

const RowDop = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px 0 8px;
  border-bottom: ${(p) => p.theme.inputBorder};
`;

const RowDopHead = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${(p) => p.theme.inputBorder};
  margin: 0 8px 0 8px;
  color: #EDA240;
`;

const DopName = styled.div`
  padding: 0 8px;
  width: calc(65% - 8px);
  border-left: ${(p) => p.theme.inputBorder};
  border-right: ${(p) => p.theme.inputBorder};
`;

const DopCol = styled.div`
  padding: 0 8px;
  width: calc(29% - 16px);
  border-right: ${(p) => p.theme.inputBorder};
  display: flex;
  align-items: center;
`;

const DopDelete = styled.div`
  padding: 0 8px;
  width: calc(6% - 16px);
`;

const TitleDopBtn = styled.div`
  font-size: 12px;
`;

const Dops = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #2D303E;
  border: 1px solid #393C49;
  border-radius: 10px;
  padding: 10px;
  z-index: 99;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.accentColor};
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const DopsHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${(p) => p.theme.inputBorder};
  padding-bottom: 5px;
`;

const OpasDops = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 80;
`;

const Oldops = styled.ul`
  max-height: 200px;
  overflow-y: auto;
  margin-top: 15px;
`;

const ItemOld = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    flex: 1 1 auto;
    padding-right: 20px;
  }
`;

const SpanTitleDop = styled.span`
    flex: 1 1 auto;
    padding-right: 20px;
    transition: 0.4s;
`;