import { uk } from "date-fns/locale";
import { useState, useEffect } from "react";
import { DateRange } from "react-date-range";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import Checkbox from "components/ui-kit/Checkbox";
import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import styles from "../scss/settings.module.scss";

import {
    RADIO_SCHEME,
    RADIO_SCHEME_PERIOD_NO,
    TYPE_CREATE_PROMOCODES,
    RADIO_SCHEME_PROMOCODE_OR_PROMOACTION,
} from "constants/schemes";
import { TextArea } from "components/ui-kit/inputs/TextArea";

import { categoriesMappedSelect, searchCategoriesId } from "utils/mappers/categories";
import { productMappedSelect, productMappedSearchSelectItem, searchProductId } from "utils/mappers/products";
import { restaurantsMappedLabel, searchRestaurantByCode } from "utils/mappers/restaurants";
import { mappedSelect, mappedSelectedItem } from "utils/mappers/other";

import { validatePromocode } from "utils/validation";
import { rangesDate } from "utils/helpers/date";
import { isArray } from "lodash";

const typePromocode = [
    {
        title: '-',
        value: '',
    },
    {
        title: 'Сума знижки (-100 від суми кошика)',
        value: 'discount_amount',
    },
    {
        title: '% знижки (-10% від суми кошику)',
        value: 'percent_amount',
    },
    {
        title: 'Страва в подарунок (піца А в подарунок)',
        value: 'free_product',
    }
];

export const EditPromocode = ({
    cities,
    products,
    categories,
    restaurants,
    promocodesEdit,
    handleSubmitClose,
    updateFormPromocode,
    handleSubmitСreate,
    handleSubmitUpdate,
    updateFormPromocodeArray,
    updateFormPromocodeObject,
    deleteOneFieldPromocode,
    addOneFieldPromocode,
    updateFormPromocodeItemCode,
    addOneFieldCodePromocode,
    deleteOneFieldCodePromocode,
    getProducts
}) => {
    const [error, setErrors] = useState({
        title: "",
        min_order_amount: "",
    });

    const [typeCreate, setTypeCreate] = useState(TYPE_CREATE_PROMOCODES[0].value);
    const [promoType, setPromoType] = useState(RADIO_SCHEME_PROMOCODE_OR_PROMOACTION[0].value);

    const [productsLoading, setProductsLoading] = useState(false);

    let titleButton = promocodesEdit?.id ? 'Змінити' : 'Додати';
    let title = promocodesEdit?.id ? `Редагування ${promocodesEdit.title}` : "Створення нового промокода";
    let titleDiscount = '';

    (typePromocode[1].value === promocodesEdit.type) && (titleDiscount = "Сума знижки");
    (typePromocode[2].value === promocodesEdit.type) && (titleDiscount = "% знижки");

    const onSubmit = () => {
        validatePromocode({
            data: {
                ...promocodesEdit
            },

            onSuccess: async () => {
                if (promocodesEdit?.id) {
                    handleSubmitUpdate(promocodesEdit?.id);
                } else {
                    handleSubmitСreate();
                }

                setErrors({
                    title: "",
                    min_order_amount: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    const onHandlerPromoType = (value) => {
        addOneFieldPromocode("promo_type", value)
        setPromoType(value);
    }

    const onHandlerType = (value) => {
        setTypeCreate(value);

        if (TYPE_CREATE_PROMOCODES[0].value === value) {
            deleteOneFieldPromocode("codes");
            addOneFieldPromocode("generate_codes", "5");
        } else if (TYPE_CREATE_PROMOCODES[1].value === value) {
            deleteOneFieldPromocode("generate_codes");
            addOneFieldPromocode("codes", [{ code: '', status: 1 }]);
        }
    }

    useEffect(() => {
        if (promocodesEdit.type === "free_product" && !!promocodesEdit.restaurant) {
            setProductsLoading(true);
            getProducts({
                short_projection: true,
                restaurant: promocodesEdit.restaurant,
            },
                setProductsLoading,
                () => { },
                true);
        }
    }, [promocodesEdit.restaurant, promocodesEdit.type]);

    useEffect(() => {
        if (promocodesEdit.id !== null) {
            console.debug('promo type', promocodesEdit.promo_type);
            return setPromoType(promocodesEdit.promo_type);
        }
    }, [setPromoType, promocodesEdit.id]);

    return (
        <div>
            <div className={`${styles.title_row} ${styles.marg_bot}`}>{title}</div>
            <div className={`${styles.row_form}`}>
                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                    <TextInput
                        title="Назва"
                        type="text"
                        value={promocodesEdit.title}
                        isStyle={true}
                        error={error.title}
                        onChange={(e) => updateFormPromocode("title", e.target.value)}
                    />
                    <div className={styles.row_item}>
                        <fieldset className={styles.fieldset}>
                            <legend>Вказати мінімальну суму кошика для застосування промокоду</legend>
                            <Stack spacing={0.5} divider={<Divider />} maxHeight='11em' overflow='auto'>
                                {cities.map(({ id, name, sync_id: code }) => (
                                    <Stack direction='row' alignItems='center' justifyContent='space-between' key={`min-order-amount-${id}`}>
                                        <p>{name}</p>
                                        <TextInput
                                            isTitle={false}
                                            title=""
                                            type="number"
                                            stylePadTop="0"
                                            styleMarg="0"
                                            value={promocodesEdit.min_order_amount[code]}
                                            isStyle={true}
                                            error={error.min_order_amount}
                                            onChange={(e) => updateFormPromocodeObject("min_order_amount", code, e.target.value)}
                                        />
                                    </Stack>
                                ))}
                            </Stack>
                            <small>* Якщо не заповнено - промокод не працюватиме в даному місті</small>
                        </fieldset>
                    </div>
                    <div className={styles.row_item}>
                        <Select
                            selectOptions={mappedSelect(typePromocode)}
                            selectedOption={mappedSelectedItem(typePromocode, promocodesEdit.type)}
                            valueKey='what_for'
                            label='На що діє'
                            mode={'primary'}
                            onChange={(option) => updateFormPromocode("type", option.value.id)}
                        />
                        {
                            ([typePromocode[1].value, typePromocode[2].value].indexOf(promocodesEdit.type) != -1) && (
                                <div className={styles.row_item}>
                                    <TextInput
                                        title={titleDiscount}
                                        type="number"
                                        value={promocodesEdit.discount}
                                        isStyle={true}
                                        onChange={(e) => updateFormPromocode("discount", e.target.value)}
                                    />
                                </div>
                            )
                        }
                        {/* { (promocodesEdit.type === "free_product") && ( */}
                        <div className={styles.row_item}>
                            <Select
                                selectOptions={restaurantsMappedLabel(restaurants)}
                                selectedOption={searchRestaurantByCode(promocodesEdit.restaurant, restaurants)}
                                valueKey='value'
                                label='Ресторан'
                                mode={'primary'}
                                onChange={(option) => {
                                    const { value: restaurantCode } = option.value;
                                    updateFormPromocode("free_product", '');
                                    updateFormPromocode("restaurant", restaurantCode);
                                }}
                            />
                        </div>
                        {/* )} */}
                        {
                            (promocodesEdit.type === "free_product" && !!promocodesEdit.restaurant) && (
                                <div className={styles.row_item}>
                                    <Select
                                        isLoad={productsLoading}
                                        selectOptions={productMappedSelect(products)}
                                        selectedOption={productMappedSearchSelectItem(products, !isArray(promocodesEdit.free_product) ? [] : promocodesEdit.free_product)}
                                        valueKey='product_as_gift'
                                        label='Страва в подарунок'
                                        mode={'primary'}
                                        onChange={(option) => updateFormPromocodeArray("free_product", option.value)}
                                        isSearchable
                                        isMulti
                                    />
                                </div>
                            )
                        }
                        {
                            (!!promocodesEdit.free_product) && (
                                <div className={styles.row_item}>
                                    <TextInput
                                        title="Ціна продукту"
                                        type="number"
                                        value={promocodesEdit.free_product_price}
                                        isStyle={true}
                                        onChange={(e) => updateFormPromocode("free_product_price", e.target.value)}
                                    />
                                </div>
                            )
                        }
                    </div>
                    <div className={styles.row_item}>
                        <Select
                            selectOptions={categoriesMappedSelect(categories)}
                            selectedOption={searchCategoriesId(promocodesEdit.exclude_categories, categories)}
                            valueKey='сategorys'
                            label='Виняток категорії'
                            isMulti
                            mode={'primary'}
                            onChange={({ value }) => updateFormPromocodeArray("exclude_categories", value)}
                        />

                        <Select
                            selectOptions={productMappedSelect(products)}
                            selectedOption={searchProductId(promocodesEdit.exclude_products, products)}
                            valueKey='exclude_products'
                            label='Виняток товари'
                            isMulti
                            isSearchable
                            mode={'primary'}
                            onChange={({ value }) => updateFormPromocodeArray("exclude_products", value)}
                        />
                    </div>
                    <div className={styles.row_item}>
                        <Radio
                            colors={"#000"}
                            items={RADIO_SCHEME_PROMOCODE_OR_PROMOACTION}
                            valueKey="typePromocodeOrPromoAction"
                            label="Тип промоакції"
                            value={promoType}
                            onChange={(e) => onHandlerPromoType(e.value.value)}
                        />
                    </div>
                    <div className={styles.row_item}>
                        <Checkbox
                            isStyle={true}
                            title="Щоденне використання"
                            checked={!!promocodesEdit?.daily_usage}
                            value={!!promocodesEdit?.daily_usage}
                            onChecked={(previous) => updateFormPromocode("daily_usage", +(!previous))}
                        />
                    </div>
                    {RADIO_SCHEME_PROMOCODE_OR_PROMOACTION[0].value === promoType && (<div className={styles.row_item}>
                        {
                            (promocodesEdit.id === null) && (
                                <Radio
                                    colors={"#000"}
                                    items={TYPE_CREATE_PROMOCODES}
                                    valueKey="typePromocode"
                                    label="Як створити промокоди"
                                    value={typeCreate}
                                    onChange={(e) => onHandlerType(e.value.value)}
                                />
                            )
                        }

                        {
                            (TYPE_CREATE_PROMOCODES[0].value === typeCreate) && (promocodesEdit.id === null) && (
                                <TextInput
                                    title="Кількість промокодів"
                                    type="number"
                                    value={promocodesEdit?.generate_codes || ''}
                                    isStyle={true}
                                    onChange={(e) => updateFormPromocode("generate_codes", e.target.value)}
                                />
                            )
                        }

                        {
                            ((TYPE_CREATE_PROMOCODES[1].value === typeCreate) || (promocodesEdit.id !== null)) && (
                                <>
                                    <div className={styles.title_rows}>Список промокодів</div>
                                    {
                                        isArray(promocodesEdit?.codes) && promocodesEdit.codes.map((item, index) => (
                                            <div className={styles.row_numb_field} key={index}>
                                                <span className={styles.number_promo}>{index + 1}.</span>
                                                <TextInput
                                                    isTitle={false}
                                                    type="text"
                                                    value={item.code}
                                                    isStyle={true}
                                                    disabled={(promocodesEdit.id !== null) && !!item?.id}
                                                    width="150px"
                                                    onChange={(e) => updateFormPromocodeItemCode(index, e.target.value)}
                                                />
                                                {
                                                    (promocodesEdit.codes.length > 1) && (
                                                        <span className={styles.row_numb_btn}>
                                                            <ButtonIcon
                                                                onClick={() => deleteOneFieldCodePromocode(index)}
                                                                colorBg="red"
                                                                icon={<DeleteForeverIcon />}
                                                            />
                                                        </span>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                    <div className={styles.row_btn}>
                                        <div className={styles.item_btn}>
                                            <ButtonIcon
                                                onClick={addOneFieldCodePromocode}
                                                title="Додати код"
                                                colorBg="green"
                                                icon={<AddIcon />}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </div>)}
                </div>
                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                    <div className={styles.wr_drop}>
                        <Radio
                            colors={"#000"}
                            items={RADIO_SCHEME_PERIOD_NO}
                            valueKey="perpetual"
                            value={promocodesEdit.perpetual}
                            onChange={(e) => updateFormPromocode("perpetual", +e.value.value)}
                        />
                    </div>
                    {
                        (promocodesEdit.perpetual == "0") && (
                            <div className={styles.wr_range}>
                                <div className={styles.title_input}>Період з - по</div>
                                <DateRange
                                    onChange={(date) => updateFormPromocode("period", [date.selection])}
                                    ranges={rangesDate(promocodesEdit.period)}
                                    locale={uk}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
            <div className={styles.row_item}>
                <TextArea
                    isStyle={true}
                    title='Опис промокода'
                    value={promocodesEdit.description}
                    onChange={(e) => updateFormPromocode("description", e.target.value)}
                />
            </div>
            <div className={styles.wr_drop}>
                <Radio
                    colors={"#000"}
                    items={RADIO_SCHEME}
                    label="Активність промокода"
                    valueKey="status"
                    value={promocodesEdit.status}
                    onChange={(e) => updateFormPromocode("status", +e.value.value)}
                />
            </div>
            <div className={styles.row_btn_tow}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={onSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmitClose}
                        icon={<CloseIcon />}
                    />
                </div>
            </div>
        </div>
    )
}