import { connect } from 'react-redux';

// selectors
import { userRestaurantsSelector } from 'selectors/user';

import { default as HomePage } from './Home';

function mapStateToProps(state) {
    const restaurants = userRestaurantsSelector(state);
    return {
        available: !!restaurants.length,
    };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
