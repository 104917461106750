import * as React from "react";

import SaveIcon from "@mui/icons-material/Save";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import { TextInput } from "components/ui-kit/inputs/TextInput";
// import { Stack, Box } from "@mui/material";
import { LoadingModal } from "components/loadingOrder/loading";
import RestaurantSchedule from "components/RestaurantSchedule";

import styles from "./../../scss/settings.module.scss";

const PageCommon = ({
  // state
  data = {},
  restaurant: {
    name: restaurant,
    code: restaurantCode,
  } = {},
  isLoadPage = false,
  isLoadEdit = false,
  // dispatch
  updateSiteSettingsFormBrands = () => { },
  getSiteSettingsFormBrands = () => { },
  submitSiteSettingsFormBrands = () => { },
}) => {

  React.useLayoutEffect(() => {
    getSiteSettingsFormBrands(restaurantCode);
  }, [getSiteSettingsFormBrands, restaurantCode]);

  return !isLoadPage ? (
    <div className={styles.d_max_1023} style={{ position: "relative" }}>
      <div className={styles.d_grid}>
        <div className={`${styles.title_row} ${styles.marg_bot}`}>Налаштування бренду {restaurant}</div>

        {isLoadEdit && (
          <div>
            <LoadingModal />
          </div>
        )}

        {/* <div className={styles.wr_drop}>
          <TextInput
            title="Мінімальна сума замовлення"
            type="number"
            value={data.min_order_amount}
            isStyle={true}
            onChange={(event) => updateSiteSettingsFormBrands("min_order_amount", event.target.value)}
          />
        </div> */}

        <div className={styles.wr_drop}>
          <TextInput
            title="Телефон"
            value={data.contacts?.phone}
            isStyle={true}
            onChange={(event) => updateSiteSettingsFormBrands("contacts.phone", event.target.value)}
          />
        </div>

        <div className={styles.wr_drop}>
          <TextInput
            title="Електронна адреса"
            value={data.contacts?.email}
            isStyle={true}
            onChange={(event) => updateSiteSettingsFormBrands("contacts.email", event.target.value)}
          />
        </div>

        <div className={styles.wr_drop}>
          <TextInput
            title="Фізична адреса"
            value={data.contacts?.address}
            isStyle={true}
            onChange={(event) => updateSiteSettingsFormBrands("contacts.address", event.target.value)}
          />
        </div>

        {/* <div className={styles.wr_drop}>
          <Stack direction="row" alignItems="center">
            Час доставки за замовчуванням -
            <Box sx={{ px: 1 }}>
              <TextInput
                title="3"
                type="number"
                value={data.default_delivery_time}
                isStyle={true}
                isTitle={false}
                onChange={(event) => updateSiteSettingsFormBrands("default_delivery_time", event.target.value)}
                width="50px"
                height="auto"
                stylePadTop="0"
                styleMarg="0"
              />
            </Box>
            хвилин
          </Stack>
        </div> */}

        <RestaurantSchedule 
          data={data} 
          updateForm={updateSiteSettingsFormBrands} 
        />

      </div>
      <div className={styles.row_btn_tow}>
        <div className={styles.item_btn}>
          <ButtonIcon
            onClick={submitSiteSettingsFormBrands}
            title="Зберегти"
            colorBg="green"
            icon={<SaveIcon />}
            disabled={isLoadEdit}
          />
        </div>
      </div>
    </div>
  ) : (<LoadingCircl />)
};


export default React.memo(PageCommon);
