// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MaintenanceAlert_alert__3XcdR {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding-left: 14px;\n  padding-right: 14px;\n  position: fixed;\n  margin-top: 50px;\n  text-align: center;\n  width: 100%;\n  height: 35px;\n  color: #fafafa;\n  font-family: \"Inter\", sans-serif;\n  background-color: #c21d3b;\n  font-size: 1.5em;\n}", "",{"version":3,"sources":["webpack://src/components/MaintenanceAlert/MaintenanceAlert.module.scss","webpack://src/assets/styles/constants.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EAEA,kBAAA;EACA,mBAAA;EAEA,eAAA;EACA,gBAAA;EACA,kBAAA;EAEA,WAAA;EACA,YAAA;EACA,cCfY;EDgBZ,gCAAA;EACA,yBCTE;EDUF,gBAAA;AAJJ","sourcesContent":["@import \"../../assets/styles/constants.scss\";\n\n.alert {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    padding-left: 14px;\n    padding-right: 14px;\n\n    position: fixed;\n    margin-top: 50px;\n    text-align: center;\n\n    width: 100%;\n    height: 35px;\n    color: $PRIMARY_LIGHT;\n    font-family: 'Inter', sans-serif;\n    background-color: $RED;\n    font-size: 1.5em;\n}\n","$PRIMARY_DARK: #23262a;\n$PRIMARY_LIGHT: #fafafa;\n$PRIMARY_ORANGE: #eda240;\n$TEXT_PRIMARY_LIGHT: rgba(256, 256, 256, 1);\n$TEXT_SECONDARY_LIGHT: rgba(256, 256, 256, 0.7);\n$TEXT_PRIMARY_DARK: #252525;\n$TEXT_SECONDARY_DARK: #425466;\n$DISABLED_TAB_TEXT: grey;\n$PRODUCT_BG: #1F1D2B;\n$RED: #c21d3b;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert": "MaintenanceAlert_alert__3XcdR"
};
export default ___CSS_LOADER_EXPORT___;
