import { useMemo } from "react";
import { useRouteMatch } from "react-router-dom";

import { SETTINGS } from "constants/routes";

import SubpageSwitch from "components/SubpageSwitch";
import MenuPage from "components/menuPage";

import styles from "./scss/settings.module.scss";

import { dataItemsMenu } from 'constants/menuPageSettings';

import PageHome from './Home';
import PageGeneral from './General';
import PageSites from './Sites';

const components = {
    common: PageHome,
    general: PageGeneral,
    sites: PageSites,
}

const Settings = ({ pages, restaurants }) => {
    const isAtSitesPage = useRouteMatch(SETTINGS + dataItemsMenu['sites'].path);
    const isRestaurantPickerSites = useMemo(() => {
        return (isAtSitesPage && !!restaurants.length) || false;
    }, [isAtSitesPage, restaurants.length]);

    return (
        <div>
            <MenuPage
                items={pages}
                isRestaurantPickerSites={isRestaurantPickerSites}
            />

            <div className={styles.block}>
                <SubpageSwitch
                    path={SETTINGS}
                    components={components}
                    pages={pages}
                />
            </div>
        </div>
    );
}

export default Settings;