import { createSelector } from 'reselect';

export const userSelector = state => state?.user?.user;

/* Subpages selector */
export const userSubpageSelector = createSelector(
  [
    userSelector,
    (_state, page) => page,
    (_state, _page, menu) => menu,
  ],
  (user, page, menu) => {

    if (user.role_name === 'system_administrator') {
      return menu; // For admin every setting menu is available
    }

    const mainPage = user.pages.find(function ({ value }) {
      return value === page;
    });

    const availablePaths = (mainPage?.pages || []).map((page) => page.value.replace(/\/$/, '')); // Remove trailing slash
    const menuEntries = Object.entries(menu).filter(function ([_key, value]) {
      return availablePaths.includes(value.path);
    });

    return Object.fromEntries(menuEntries);
  },
);

/* Restaurant selector */

const getUserData = (state) => state.user?.user || {};
const getAllRestaurants = (state) => state.settings?.restaurants || [];

export const userRestaurantsSelector = createSelector(
  [getUserData, getAllRestaurants],
  ({ restaurants, role_name: roleName }, allRestaurants) => allRestaurants.filter(function (restaurant) {
    // Restaurant is assigned when the user is sysadmin or it is manually assigned to them
    return roleName === 'system_administrator' || 
      restaurants?.includes(restaurant.id);
  }),
);
