import { isArray } from "lodash"

export const deduplicateIds = (array) => {
    return isArray(array) ? [...new Set(array)] : array;
}

export const remomveDublicatesDeliveryPayments = (arr) => {
    if (!isArray(arr))
        return arr;

    const colectionIds = [];

    arr.map((item) => {
        colectionIds.push(item.id);
    });

    let conver = [...new Set(colectionIds)];

    return arr.filter(item => {
        return conver.indexOf(item.id) != -1;
    });
}